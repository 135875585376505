<template>
    <router-view></router-view>
</template>
<script>
export default {
    created() {
        localStorage.setItem('authToken', this.$route.query.authToken);
        this.$store.commit('initParams', this.$route.query);
    },
};
</script>
