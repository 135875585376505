<template>
  <div class="bottom_controller">
    <div
      class="selected_bar"
      v-if="(containerMaterial || selectedMaterial) && flowerHeightShow"
    >
      <div v-if="containerMaterial" class="mb-10">
        <div>容器</div>
        <div>宽:{{ ((containerMaterial.radius * 2) / 28).toFixed(2) }}</div>
        <div>高:{{ (containerMaterial.height / 28).toFixed(2) }}</div>
      </div>
      <div v-if="selectedMaterial">
        <div
          style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis"
        >
          {{ selectedMaterial.name }}
        </div>
        <div>高:{{ (selectedMaterial.height / 28).toFixed(2) }}</div>
      </div>
    </div>
    <div
      class="riso_bar lv_flex_center"
      :class="{ riso_bar_actived: selectedMaterial }"
      @click="openRiso"
    >
      花材知识
    </div>
    <div
      class="riso_bar lv_flex_center riso_bar_actived"
      style="top: 50px"
      @click="changeRenderModel"
      v-if="['schoolwork', 'competition'].indexOf($route.query.type) === -1"
    >
      {{ isRenderModel ? "3d" : "2d" }}编辑器
    </div>
    <div v-if="$store.state.routeParams.mode === 'correct'" class="preview">
      <div class="title">批改前<van-icon name="arrow-up" /></div>
      <van-swipe class="my-swipe">
        <van-swipe-item
          v-for="(item, index) in info.schoolworkImages"
          :key="index"
        >
          <img
            :src="'https://lvshuhualv.oss-cn-hangzhou.aliyuncs.com/' + item"
            @click="imgPreview(info.schoolworkImages, index)"
          />
        </van-swipe-item>
      </van-swipe>
    </div>
    <div
      v-if="
        $store.state.routeParams.mode === 'examine' &&
        info.correctingSchoolworkImages
      "
      class="preview"
    >
      <div class="title">批改后<van-icon name="arrow-up" /></div>
      <van-swipe class="my-swipe">
        <van-swipe-item
          v-for="(item, index) in info.correctingSchoolworkImages"
          :key="index"
        >
          <img
            :src="'https://lvshuhualv.oss-cn-hangzhou.aliyuncs.com/' + item"
            @click="imgPreview(info.correctingSchoolworkImages, index)"
          />
        </van-swipe-item>
      </van-swipe>
    </div>
    <template
      v-if="
        $store.state.routeParams.mode === 'edit' ||
        $store.state.routeParams.mode === 'correct'
      "
    >
      <div
        class="count_down"
        v-if="
          $store.state.routeParams.mode === 'edit' &&
          $store.state.routeParams.type !== 'practice'
        "
      >
        {{ countdown }}
      </div>
      <div v-show="actActived">
        <div class="act">
          <div
            v-for="(item, index) in acts"
            :key="index"
            :class="{
              actived: index === actived,
              hide: index === 4 && $store.state.routeParams.type !== 'practice',
            }"
            @click="handleClick(index)"
          >
            {{ item }}
          </div>
        </div>
        <div class="act_detail lv_flexalign_center">
          <winding v-show="actived === 0" ref="winding" />
          <flower-head v-show="actived === 1" ref="flowerHead" />
          <remove v-show="actived === 2" :selectedMaterial="selectedMaterial" />
          <copy v-show="actived === 3" :selectedMaterial="selectedMaterial" />
          <container v-show="actived === 5" />
          <jianshan v-show="actived === 6" />
          <touch v-show="actived === 7" />
          <bindType v-show="actived === 8" />
          <angle v-show="actived === 9" />
          <canvas-lock v-show="actived === 10" />
          <flower-height v-show="actived === 11" />
          <change-camera v-show="actived === 12" />
          <background v-show="actived === 13" />
          <layer v-show="actived === 14" @layerShow="layerShow" />
        </div>
      </div>
      <div class="lv_flexalign_center">
        <div @click="wxReturn">返回</div>
        <div class="lv_flex">
          <div @click="$refs.choosePop.open()">花材</div>
          <div
            :class="{ act_text_actived: actActived }"
            @click="actActived = !actActived"
          >
            调整
          </div>
        </div>
        <div
          v-if="$store.state.routeParams.mode === 'correct'"
          @click="correctShow = true"
        >
          重做/通过
        </div>
        <div v-else @click="save">保存</div>
      </div>
      <div
        v-if="$store.state.routeParams.type !== 'practice'"
        class="work_bar lv_flex"
      >
        <div class="lv_flex_center" @click="reqShow = true">
          <i class="iconfont icon-tiezi"></i
          >{{ $store.state.routeParams.text }}要求
        </div>
        <div class="lv_flex_center" @click="inputShow = true">
          <i class="iconfont icon-fenxiang"></i>填写描述
        </div>
      </div>
    </template>
    <div v-else-if="$store.state.routeParams.type !== 'practice'">
      <div class="lv_flex exam_bar">
        <div @click="reqShow = true">作业要求</div>
        <div @click="$refs.choosePop.open()">可用素材</div>
        <div @click="inputShow = true">作业描述</div>
        <div
          v-if="
            $store.state.routeParams.type === 'schoolwork' && info.status === 3
          "
          @click="teacher.show = true"
        >
          老师评语
        </div>
      </div>
    </div>

    <van-popup v-model="reqShow" position="bottom" round>
      <div class="req_poup">
        <div v-if="$store.state.routeParams.type === 'schoolwork'">
          <div class="label">关联课件（必看）</div>
          <div class="link lv_flexalign_center" @click="coursewareShow = true">
            <i class="iconfont icon-kaifang" />{{ info.coursewareTitle }}
          </div>
        </div>
        <div>
          <div class="label">{{ $store.state.routeParams.text }}要求</div>
          <div class="text">{{ info.request }}</div>
        </div>
      </div>
    </van-popup>

    <van-popup v-model="inputShow" position="bottom" round>
      <div class="input_pop">
        <van-field
          v-model="form.name"
          label="作品命名"
          required
          placeholder="填写作品名称"
          :readonly="$store.state.routeParams.mode === 'examine'"
        />

        <van-field
          required
          v-model="form.desc"
          rows="3"
          label="填写描述"
          type="textarea"
          placeholder="填写作品描述"
          :readonly="$store.state.routeParams.mode === 'examine'"
          v-if="hasDesc"
        />
      </div>
    </van-popup>

    <van-popup v-model="teacher.show" position="bottom" round>
      <div class="input_pop">
        <van-field v-model="teacher.res" label="作业结果" readonly />

        <van-field
          readonly
          v-model="teacher.desc"
          rows="3"
          label="老师评语"
          type="textarea"
          placeholder="填写作品描述"
        />
      </div>
    </van-popup>

    <van-popup v-model="correctShow" position="bottom" round>
      <div class="correct-pop">
        <div>同步批改后的效果图至学生</div>
        <div class="lv_flex">
          <div
            :class="{ actived: correctIndex === 0 }"
            @click="correctIndex = 0"
          >
            同步
          </div>
          <div
            :class="{ actived: correctIndex === 1 }"
            @click="correctIndex = 1"
          >
            不同步
          </div>
        </div>
        <div>学生作业描述</div>
        <div>-</div>
        <div>填写评语（非必填）</div>
        <textarea placeholder="请填写评语" v-model="schoolworkRemark" />
        <div class="lv_flex">
          <div @click="correct(1)">通过作业</div>
          <div @click="correct(0)">发回重做</div>
        </div>
      </div>
    </van-popup>

    <van-dialog
      v-model="coursewareShow"
      title="查看课件"
      confirmButtonText="关闭"
    >
      <video
        id="ls_vedio"
        autoplay="true"
        controls="true"
        v-if="coursewareShow"
      >
        <source :src="info.coursewareUrl" type="video/mp4" />
      </video>
    </van-dialog>
    <van-popup
      v-model="risoShow"
      position="right"
      :style="{ width: '80%', height: '100vh' }"
      round
    >
      <van-collapse v-model="activeNames" v-if="selectedMaterial">
        <van-collapse-item
          :title="item"
          :name="key"
          v-for="(item, key) in tabs"
          :key="key"
        >
          <div v-if="key === 'basicInfo'" style="color: #ff8f2f">
            名称：{{ selectedMaterial.name }}
          </div>
          {{ selectedMaterial.resource[key] }}
        </van-collapse-item>
      </van-collapse>
    </van-popup>
    <choosePop ref="choosePop" />
  </div>
</template>

<script>
import { ImagePreview } from "vant";
import wx from "weixin-js-sdk";
import choosePop from "@/components/material/choosePop";
import winding from "@/components/act/winding";
import flowerHead from "@/components/act/flowerHead";
import remove from "@/components/act/remove";
import touch from "@/components/act/touch";
import bindType from "@/components/act/bindType";
import angle from "@/components/act/angle";
import copy from "@/components/act/copy";
import container from "@/components/act/container";
import jianshan from "@/components/act/jianshan.vue";
import background from "@/components/act/background.vue";
import canvasLock from "@/components/act/canvasLock.vue";
import flowerHeight from "@/components/act/flowerHeight.vue";
import changeCamera from "@/components/act/changeCamera.vue";
import layer from "@/components/act/layer.vue";

import { Dialog, Toast } from "vant";
import dayjs from "dayjs";
export default {
  components: {
    copy,
    winding,
    flowerHead,
    remove,
    touch,
    bindType,
    angle,
    container,
    jianshan,
    choosePop,
    canvasLock,
    flowerHeight,
    changeCamera,
    background,
    layer,
  },
  data() {
    return {
      schoolworkRemark: "",
      correctIndex: 0,
      correctShow: false,
      isRenderModel: true,
      tabs: {
        basicInfo: "基础信息",
        collocation: "插花搭配",
        connotation: "文化内涵",
        colorSuggestion: "色彩搭配",
        compositionSuggestion: "构图建议",
        technicalSuggestion: "技术特点",
      },
      activeNames: ["basicInfo"],
      risoShow: false,
      teacher: {
        show: false,
        desc: null,
        res: "通过",
      },
      form: {
        name: null,
        desc: null,
      },
      inputShow: false,
      coursewareShow: false,
      reqShow: false,
      info: "",
      countdown: "",
      hasDesc: false,
      tId: null,
      actActived: false,
      selectedMaterial: null,
      containerMaterial: null,
      flowerHeightShow: false,
      actived: 0,
      acts: [
        "弯曲",
        "花头",
        "删除",
        "复制",
        "替换",
        "容器",
        "剑山",
        "焦点",
        "插法",
        "角度",
        "画布",
        "花高",
        "缩放",
        "背景",
        "图层",
      ],
    };
  },
  created() {
    this.$bus.$on("flower-height-show", (val) => {
      this.flowerHeightShow = val;
    });

    this.$bus.$on("container-add", (val) => {
      this.containerMaterial = val;
    });

    this.$bus.$on("flower-choose", (val) => {
      // if (this.selectedMaterial && this.selectedMaterial.id === val.id) {
      //   return;
      // }
      this.selectedMaterial = val;

      if (!val) {
        return;
      }
      this.$refs.winding.init(val);
      this.$refs.flowerHead.init(val);
    });

    this.$bus.$on("renderModelInit", (val) => {
      this.isRenderModel = val;
    });
  },
  mounted() {
    if (this.$store.state.routeParams.type !== "practice") {
      this.getRequest(this.$store.state.routeParams.type);
    }
  },
  methods: {
    correct(type) {
      if (type) {
        this.$bus.$emit("correct", {
          correctIndex: this.correctIndex,
          schoolworkRemark: this.schoolworkRemark,
        });
      } else {
        this.$bus.$emit("correct", false);
      }
      this.correctShow = false;
    },
    imgPreview(images, startPosition) {
      ImagePreview({
        images: images.map(
          (item) => "https://lvshuhualv.oss-cn-hangzhou.aliyuncs.com/" + item
        ),
        startPosition,
      });
    },
    changeRenderModel() {
      this.isRenderModel = !this.isRenderModel;
      this.$bus.$emit("changeRenderModel", this.isRenderModel);
    },
    layerShow(isOne) {
      this.$bus.$emit(
        "layer-show",
        isOne ? (this.selectedMaterial ? this.selectedMaterial.id : "") : ""
      );
    },
    openRiso() {
      if (!this.selectedMaterial) {
        return;
      }
      this.activeNames = ["basicInfo"];
      this.risoShow = true;
    },
    getRequest(type) {
      this.$ajax
        .get(`/${type}/student/getById`, {
          params: {
            id: this.$store.state.routeParams.id,
          },
        })
        .then((res) => {
          const resData = res.data;
          this.info = {
            correctingSchoolworkImages: resData.correctingSchoolworkImages,
            schoolworkImages: resData.schoolworkImages,
            name: resData[type + "Name"],
            request: resData[type + "Request"],
            useMaterials: resData[type + "UseMaterials"],
            submitEndTime:
              type === "competition"
                ? resData.competitionEndTime
                : resData.submitEndTime,
            hasTimeout: resData.hasTimeout,
            coursewareTitle: resData.coursewareTitle,
            coursewareUrl: resData.coursewareUrl,
            status: resData.status,
          };
          sessionStorage.setItem("startTime", new Date().getTime());
          this.hasDesc = type === "competition" ? true : resData.hasDesc;
          sessionStorage.setItem(
            "limit",
            JSON.stringify(resData[type + "UseMaterials"][0].useMaterials)
          );
          this.$store.commit(
            "initLimitMaterials",
            resData[type + "UseMaterials"]
          );

          if (this.$store.state.routeParams.mode === "examine") {
            this.form.name = resData.productName;
            this.form.desc = resData[type + "Desc"];
            document.title = resData.productName;
            if (this.$store.state.routeParams.type === "schoolwork") {
              this.teacher.desc = resData.schoolworkRemark;
            }
          }

          this.countDown(resData.hasTimeout);
          this.tId = setInterval(() => {
            this.countDown(resData.hasTimeout);
          }, 1000);
        });
    },
    countDown(hasTimeout) {
      var subtime = (dayjs(this.info.submitEndTime) - dayjs()) / 1000;

      var days = parseInt(subtime / 86400);
      var hours = parseInt(subtime / 3600) - 24 * days;
      var minutes = parseInt((subtime % 3600) / 60);
      var seconds = parseInt(subtime % 60);

      if (
        hours <= 0 &&
        minutes <= 0 &&
        seconds <= 0 &&
        this.$store.state.routeParams.mode === "edit"
      ) {
        clearInterval(this.tId);
        if (hasTimeout) {
          this.countdown = "已经超时";
        } else {
          Dialog.alert({
            title: "提示",
            message: "作答时间已到",
          }).then(() => {
            this.wxReturn();
          });
        }
      } else {
        this.countdown = `${days}天 ${hours}小时 ${minutes}分 ${seconds} 秒`;
      }
    },
    wxReturn() {
      wx.miniProgram.navigateBack({});
    },
    handleClick(index) {
      this.actived = index;
      if (index === 4) {
        this.$refs.choosePop.open({
          classifyId1: 1,
          id: this.selectedMaterial ? this.selectedMaterial.id : null,
        });
      }
    },
    save() {
      if (this.$store.state.routeParams.type === "practice") {
        this.$bus.$emit("save", "practice");
      } else {
        if (!this.form.name || (!this.form.desc && this.hasDesc)) {
          Toast("请点击右下角，完善要求");
          return;
        }

        Dialog.confirm({
          title: "提交" + this.$store.state.routeParams.text,
          message: `提交前请检查是否完成，确认是否提交${this.$store.state.routeParams.text}？`,
        })
          .then(() => {
            this.$bus.$emit("save", "work", this.form);
          })
          .catch(() => {});
      }
    },
  },
};
</script>
<style lang="scss">
.slider_text {
  width: rem(90);
  text-align: center;
}
.van-collapse-item {
  .van-cell__title {
    text-align: center;
  }
}
.exam_bar {
  margin: rem(20);
  background: #fff;
  font-weight: bold;
  border-radius: rem(10);
  border: 1px solid #dedede;
  padding: rem(20) 0;
  > div {
    line-height: rem(50);
    font-size: rem(28);
    flex: 1;
    text-align: center;
    + div {
      border-left: 1px solid #dcdcdc;
    }
  }
}

.act_button {
  flex: 1;
  line-height: rem(40);
  border: 1px solid #dcdcdc;
  border-radius: rem(14);
  text-align: center;
  font-size: rem(24);
  background: #fff;
}
.act_button + .act_button {
  margin-left: rem(20);
}
.actived {
  background: #e8efd5;
}
</style>
<style lang="scss" scoped>
.correct-pop {
  font-size: rem(28);
  padding: rem(40) rem(50);
  textarea {
    outline: none;
    width: 100%;
    border: none;
    height: rem(150);
  }

  > div {
    &:nth-child(1) {
      font-weight: bold;
      margin-bottom: rem(17);
    }
    &:nth-child(2) {
      > div {
        flex: 1;
        justify-content: space-between;
        + div {
          margin-left: rem(23);
        }
        line-height: rem(52);
        border-radius: rem(14);
        border: 1px solid #dcdcdc;
        text-align: center;
        background: #f7f7f7;
        color: #666666;
      }
      .actived {
        background: #e8efd5;
      }
    }
    &:nth-child(3) {
      font-weight: bold;
      margin: rem(53) 0 rem(17) 0;
    }
    &:nth-child(4) {
      color: #666;
      padding-bottom: rem(35);
      border-bottom: 1px solid #c9c9c9;
    }
    &:nth-child(5) {
      font-weight: bold;
      margin: rem(26) 0 rem(11) 0;
    }
    &:last-child {
      border-top: 1px solid #ccc;
      padding-top: rem(30);
      > div {
        flex: 1;
        text-align: center;
        &:first-child {
          color: #31a458;
          border-right: 1px solid #ccc;
        }
      }
    }
  }
}
.preview {
  width: rem(270);
  height: rem(405);
  border-radius: rem(10);
  background: #fff;
  border: 1px solid #dedede;
  .title {
    height: rem(65);
    line-height: rem(65);
    font-size: rem(28);
    padding: 0 rem(20);
    border-bottom: 1px solid #eee;
    i {
      margin-left: rem(110);
    }
  }
  img {
    width: rem(270);
    height: rem(340);
    object-fit: contain;
  }
  position: fixed;
  top: rem(120);
  right: rem(25);
}
.mb-10 {
  margin-bottom: rem(10);
}
.selected_bar {
  width: rem(90);
  padding: rem(10) rem(5);
  box-sizing: border-box;
  position: fixed;
  top: rem(280);
  border-radius: rem(14);
  left: rem(27);
  background: #f7f7f7;
  border: 1px solid #dcdcdc;
  font-size: rem(20);
  color: #333;
}

.riso_bar {
  position: fixed;
  right: rem(18);
  top: rem(28);
  height: rem(53);
  width: rem(160);
  border: 1px solid #dcdcdc;

  border-radius: rem(27);
  background: #fff;
  color: #d6d6d6;
  > div {
    width: rem(16);
    height: rem(16);
    background: #dcdcdc;
    border-radius: 50%;
    margin: 0 rem(15);
  }
}

.riso_bar_actived {
  color: #6e9f21;
  background: #e8efd5;
  > div {
    background: #6e9f21;
    animation: colorChange 1.5s infinite;
  }
}

@keyframes colorChange {
  0% {
    background: #6e9f21;
  }
  50% {
    background: #ff8f2f;
  }
  100% {
    background: #6e9f21;
  }
}

.input_pop {
  padding: rem(20) 0;
}
.req_poup {
  padding: rem(20) rem(29) rem(59) rem(29);
  .label {
    font-size: rem(28);
    padding: rem(22) 0 rem(11) 0;
    font-weight: bold;
  }
  .text {
    font-size: rem(26);
    color: #666;
    line-height: rem(40);
  }
  .link {
    font-size: rem(26);
    color: #007cd9;
    i {
      margin-right: rem(4);
    }
    padding-bottom: rem(29);
    border-bottom: 1px solid #eee;
  }
}
.act_text_actived {
  position: relative;
  &:after {
    content: "";
    position: absolute;
    width: rem(20);
    height: rem(5);
    background: #6e9f21;
    left: rem(18);
    bottom: rem(-10);
  }
}
.bottom_controller {
  position: fixed;
  bottom: 0;
  width: 100vw;
  > .lv_flexalign_center {
    justify-content: space-between;
    height: rem(100);
    padding: 0 rem(40);
    font-size: rem(30);
    font-weight: bold;
    .lv_flex {
      font-size: rem(26);
      font-weight: normal;
      > div + div {
        margin-left: rem(65);
      }
    }
  }
  .act {
    white-space: nowrap;
    overflow-x: auto;
    padding: 0 rem(340);
    &::-webkit-scrollbar {
      display: none;
    }
    > div {
      line-height: rem(70);
      width: rem(70);
      font-size: rem(24);
      background: #fff;
      border-radius: 50%;
      border: 1px solid #f1f1f1;
      text-align: center;
      display: inline-block;
      + div {
        margin-left: rem(20);
      }
    }
    .actived {
      background: #e8efd5;
      color: #6e9f21;
    }
    .hide {
      display: none;
    }
  }
  .act_detail {
    height: rem(150);
    > div {
      width: 100vw;
    }
  }
  .act_layout {
    padding: 0 rem(40);
  }
}
.work_bar {
  background: #fff;
  padding: rem(20) 0;
  > div {
    flex: 1;
    height: rem(50);
    color: #666;
    font-size: rem(28);
    i {
      margin-right: rem(17);
      color: #2e3531;
      font-size: rem(30);
    }
    + div {
      border-left: 1px solid #dcdcdc;
    }
  }
}
.count_down {
  position: fixed;
  font-size: rem(26);
  top: rem(40);
  width: rem(400);
  text-align: center;
  left: rem(200);
}
#ls_vedio {
  width: 100%;
  height: 50vh;
}
</style>